import React from 'react';
import { GeistProvider, CssBaseline, Themes } from '@geist-ui/core';
import WindowControls from '../components/WindowControls';
import { wind } from '~/root';

interface AuthLayoutProps {
    children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    return (
        <GeistProvider themes={[Themes.createFromDark({ type: 'dark1', palette: { background: "#151515" } })]} themeType={'dark1'}>
            <CssBaseline />
            <div style={{
                cursor: 'default',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column'
            } as React.CSSProperties}>
                <div style={{
                    padding: '10px',
                    '-webkit-app-region': 'drag'
                } as React.CSSProperties}>
                    {wind?.env?.electron && (
                        <>
                            <div style={{ '-webkit-app-region': 'drag', width: '100%', height: '48px', position: 'absolute', top: 0, left: 0 } as React.CSSProperties} />
                            <WindowControls />
                        </>
                    )}
                </div>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {children}
                </div>
            </div>
        </GeistProvider>
    );
};

export default AuthLayout;